/*
 * Following colors come from this palette: green
 * http://paletton.com/#uid=7340v0kw0AGi2KLozE5A-pWFsiK
 */

:root {

  /* main color */

  /* social colors */

  /* white */

  /* black */

  /* misc colors */

  /*
   * grays
   * An exception on light gray to use a different scale, because the
   * starting color is already so light
   */

  /* charcoals (darker than gray) */ /* Keep this around #666 */

  /* palette */

  /* colors used for text */
}

:root {

  /* generic borders */

  /* borders used for forms/inputs */
}

.s-alert-box {
  font-family: inherit;
}

.image-gallery-fullscreen-button:hover::before {
    color: rgb(184, 184, 184)
}

.image-gallery-play-button:hover::before {
    color: rgb(184, 184, 184)
}

.image-gallery-left-nav:hover::before {
    color: rgb(184, 184, 184)
}

.image-gallery-right-nav:hover::before {
    color: rgb(184, 184, 184)
}

.image-gallery-thumbnail.active {
  border-color: rgb(83, 83, 83);
}

.slick-fixed-height.slick-slider {
    padding-bottom: 0;
  }

.slick-fixed-height .slick-slide {
    height: auto;
  }

.slick-fixed-height .slick-list,
  .slick-fixed-height .slick-track,
  .slick-fixed-height .slick-slide > div {
    height: inherit;
  }

.slick-fixed-height .slick-slide > div {
    display: flex;
    align-items: center;
  }

.slick-prev,
.slick-next {
  z-index: 5;
  font-size: inherit
}

.slick-prev::before, .slick-next::before {
    content: '';
  }

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-slider {
  padding-bottom: 1.625rem;
}

.slick-dots {
  left: 0;
  bottom: 2px;
}

/* This is a hack to prevent scrolling glitch :( */

.react-calendar-timeline-no-scroll .rct-scroll {
  overflow-x: hidden;
}

.rc-tooltip {
  opacity: 1;
  z-index: 5100;
}

/* Not sure best way to style this
.rc-tooltip-inner {
  color: var(--textColor);
  background-color: var(--white);
  padding: .5vr;
}

.rc-tooltip-content .rc-tooltip-arrow {
  border-color: var(--white);
}
*/

/* For MomentInput */

.react-datepicker-wrapper {
  /* min-width: 13vr; */
  display: block;
}

/* For MomentInput */

.react-datepicker__input-container {
  width: 100%;
}

.vjs-preview.vjs-big-play-button {
  display: block;
  font-size: 0.8125rem;
  cursor: default;
  z-index: 1;
}

/* Only way to override injected styles by videojs-youtube :( */

.vjs-youtube .vjs-poster {
  background-size: contain !important;
}

.vjs-cover,
  .vjs-cover video {
    -o-object-fit: cover;
       object-fit: cover;
  }

.vjs-cover .vjs-poster {
    background-size: cover;
  }
